.page-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.page-header {

}

.page-body {
	flex: 1;
    /*background: #ecd1f1;*/
}

.page-footer {

}
