$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 720px,
  xl: 720px,
  xxl: 720px
);

$primary: #555;


/*$basic-color: #d5e9ff;
$boxed-color: #d3fa97;
$recipe-color: #ffffc0;
$meal-color: #b1bcff;
*/

$basic-color: #aed5ff;
$boxed-color: #cdffa9;
$recipe-color: #ffff87;
$meal-color: #d7caff;

