/* @import "~bootstrap/scss/bootstrap";*/

.header {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	align-items: center;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

	& > div {
		padding:2px;
		&.mid {
			font-weight: bold;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}
}


.title-text {
    display: inline-block;	
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    max-width: calc(100vw - 13em);
}


@media (min-width: 720px) {
	.title-text {
		max-width: calc(720px - 13em);
	}
}




.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: #f1f1f1;
    height: 36px;
    align-items: center;
    text-shadow: 1px 1px #fff;

	& > a {
		color:#666;
		text-decoration: none;
		&.active {
			color:#000;
			text-decoration: underline;
		}
	}
}